import victoria from '../assets/images/avatars/victoria.jpg';
import avatar1 from '../assets/images/avatars/avatar1.jpg';
import avatar3 from '../assets/images/avatars/avatar3.jpg';
import avatar4 from '../assets/images/avatars/avatar4.jpg';
import avatar6 from '../assets/images/avatars/avatar6.jpg';
import avatar7 from '../assets/images/avatars/avatar7.jpg';

import { initStore } from './store';

import moment from 'moment';
import 'moment/min/locales';

const today = moment().startOf('day');
const yesterday = moment()
  .startOf('day')
  .subtract(1, 'days');
const daybeforeyesterday = moment()
  .startOf('day')
  .subtract(2, 'days');

const configureStore = () => {
  const actions = {
    JOIN_ACTION: (curState, payload) => {
      console.log('JOIN_ACTION ');
      const updatedProfile = { ...curState.profile };
      updatedProfile.activeActions.push({
        id: payload.action.id,
        start: moment()
          .startOf('day')
          .unix()
      });
      updatedProfile.feedback.push({
        daystamp: moment()
          .startOf('day')
          .unix(),
        actionId: payload.action.id,
        actionTitle: payload.action.title,
        actionFeedbackText: payload.action.feedbackText,
        actionPoints: payload.action.points,
        answer: 'noAnswer'
      });
      console.log('Updated Profile:');
      console.log(updatedProfile);
      return { ...curState, profile: updatedProfile };
    },
    LEAVE_ACTION: (curState, payload) => {
      console.log('LEAVE_ACTION ' + JSON.stringify(payload));
      const updatedProfile = { ...curState.profile };
      updatedProfile.activeActions = updatedProfile.activeActions.filter(
        action => action.id !== payload.id
      );
      return { ...curState, profile: updatedProfile };
    },
    SUPPORT_ACTION: (curState, payload) => {
      console.log('SUPPORT_ACTION ');
      const updatedProfile = { ...curState.profile };
      updatedProfile.activeActions.push({
        id: payload.action.id,
        start: moment().startOf('day')
      });
      updatedProfile.feedback.push({
        daystamp: moment()
          .startOf('day')
          .unix(),
        actionId: payload.action.id,
        actionTitle: payload.action.title,
        actionFeedbackText: payload.action.feedbackText,
        actionPoints: payload.action.points,
        answer: 'noAnswer'
      });
      return { ...curState, profile: updatedProfile };
    },
    DONE_DAILY_ACTIVITY: (curState, payload) => {
      console.log('DONE_DAILY_ACTIVITY ');
      const pointsAdded = payload.feedbackAction.actionPoints;
      const updatedFeedback = [...curState.profile.feedback];

      const index = updatedFeedback.findIndex(feedback => {
        return (
          feedback.daystamp === payload.feedbackAction.daystamp &&
          feedback.actionId === payload.feedbackAction.actionId
        );
      });
      updatedFeedback[index] = { ...updatedFeedback[index], answer: 'done' };
      const updatedProfile = {
        ...curState.profile,
        pointsAvailable: curState.profile.pointsAvailable + pointsAdded,
        pointsCollected: curState.profile.pointsCollected + pointsAdded,
        feedback: updatedFeedback
      };
      return { ...curState, profile: updatedProfile };
    },
    FAILED_DAILY_ACTIVITY: (curState, payload) => {
      console.log('FAILED_DAILY_ACTIVITY ');
      const updatedFeedback = [...curState.profile.feedback];
      const index = updatedFeedback.findIndex(feedback => {
        return (
          feedback.daystamp === payload.feedbackAction.daystamp &&
          feedback.actionId === payload.feedbackAction.actionId
        );
      });
      updatedFeedback[index] = { ...updatedFeedback[index], answer: 'failed' };
      const updatedProfile = {
        ...curState.profile,
        feedback: updatedFeedback
      };
      return { ...curState, profile: updatedProfile };
    }
  };

  initStore(actions, {
    profile: {
      name: 'Victoria Jagalski',
      activeActions: [
        { id: 4, start: 1591567200 },
        { id: 5, start: 1591567200 }
      ],
      finishedActions: [],
      pointsAvailable: 7,
      pointsCollected: 43,
      pointsSpent: 42,
      co2: 4590,
      feedback: [
        {
          daystamp: today.unix(),
          actionId: 4,
          actionTitle: 'Bike Challenge',
          actionFeedbackText:
            'Bist du heute mit dem Fahrrad zur Arbeit gekommen?',
          actionPoints: 5,
          answer: 'noAnswer'
        },
        {
          daystamp: today.unix(),
          actionId: 5,
          actionTitle: 'VEGGIE Challenge',
          actionFeedbackText:
            'Hast du heute mittag was leckeres vegetarisches gegessen?',
          actionPoints: 4,
          answer: 'noAnswer'
        },
        {
          daystamp: yesterday.unix(),
          actionId: 4,
          actionTitle: 'Bike Challenge',
          actionFeedbackText:
            'Bist du heute mit dem Fahrrad zur Arbeit gekommen?',
          actionPoints: 5,
          answer: 'done'
        },
        {
          daystamp: yesterday.unix(),
          actionId: 5,
          actionTitle: 'VEGGIE Challenge',
          actionFeedbackText:
            'Hast du heute mittag was leckeres vegetarisches gegessen?',
          actionPoints: 4,
          answer: 'done'
        },
        {
          daystamp: daybeforeyesterday.unix(),
          actionId: 4,
          actionTitle: 'Bike Challenge',
          actionFeedbackText:
            'Bist du heute mit dem Fahrrad zur Arbeit gekommen?',
          actionPoints: 5,
          answer: 'done'
        },
        {
          daystamp: daybeforeyesterday.unix(),
          actionId: 5,
          actionTitle: 'VEGGIE Challenge',
          actionFeedbackText:
            'Hast du heute mittag was leckeres vegetarisches gegessen?',
          actionPoints: 4,
          answer: 'done'
        }
      ],
      projectsSupported: [],
      avatar: victoria,
      department: 'Online Marketing',
      description: '',
      activeTeams: [
        {
          title: 'Team Marketing',
          member: [
            { imagesrc: avatar1 },
            { imagesrc: avatar7 },
            { imagesrc: avatar6 },
            { imagesrc: avatar3 },
            { imagesrc: avatar4 }
          ],
          motto: 'Eine bessere Welt für unsere Kinder',
          description: 'Wir wollen die Umstellung auf Ökostrom erreichen'
        },
        {
          title: 'Fahrradgruppe',
          member: [
            { imagesrc: avatar1 },
            { imagesrc: avatar4 },
            { imagesrc: avatar6 },
            { imagesrc: avatar7 }
          ],
          motto: 'Sport am Morgen, vertreibt Kummer und Sorgen ;-)',
          description: 'Wir lassen ein Monat das Auto stehen'
        }
      ],
      follower: [
        {
          name: 'Albert Tross',
          department: 'IT',
          imagesrc: avatar4
        },
        {
          name: 'Gerd Nehr',
          department: 'Produktion',
          imagesrc: avatar3
        },
        {
          name: 'Karl Auer',
          department: 'Produktion',
          imagesrc: avatar6
        },
        {
          name: 'Tim Buktu',
          department: 'Service',
          imagesrc: avatar7
        }
      ]
    }
  });
};

export default configureStore;
