import React, { Fragment } from 'react';

import clsx from 'clsx';

import { Avatar, Box, Button } from '@material-ui/core';

import avatar2 from '../../assets/images/logos/wag.png';
import { connect } from 'react-redux';

const SidebarUserbox = props => {
  const { sidebarToggle, sidebarHover } = props;

  return (
    <Fragment>
      <Box
        className={clsx('app-sidebar-userbox', {
          'app-sidebar-userbox--collapsed': sidebarToggle && !sidebarHover
        })}>
        <Avatar
          alt="Westfalen AG"
          src={avatar2}
          className="app-sidebar-userbox-avatar"
        />
        <Box className="app-sidebar-userbox-name">
          <Box>
            <b>Westfalen AG</b>
          </Box>
          <Box className="app-sidebar-userbox-description">
            Gewissen ist Macht
          </Box>
          {/* <Box className="app-sidebar-userbox-btn-profile">
            <Button
              size="small"
              color="secondary"
              variant="contained"
              className="text-white"
              href="/PagesProfile">
              Profil anzeigen
            </Button>
          </Box> */}
        </Box>
      </Box>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover
});

export default connect(mapStateToProps)(SidebarUserbox);
