import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import StartIcon from '@material-ui/icons/Stars';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import FaceIcon from '@material-ui/icons/Face';
import ShopIcon from '@material-ui/icons/Shop';

var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  StartIcon: StartIcon,
  MonetizationOnIcon: MonetizationOnIcon,
  ThumbUpIcon: ThumbUpIcon,
  NotificationsIcon: NotificationsIcon,
  EmojiObjectsIcon: EmojiObjectsIcon,
  FaceIcon: FaceIcon,
  ShopIcon: ShopIcon
};

export default [
  {
    label: 'Navigation',
    content: JSON.parse(
      `[
    {
      "label": "Profil",
      "icon": "FaceIcon",
      "description": "Alle Informationen zum meinem Profil sind hier zu finden",
      "to": "/DashboardProfile"
    },
    {
      "label": "Mitmachen",
      "icon": "StartIcon",
      "description": "Hier finde ich alle aktiven und möglichen Aktionen",
      "to": "/DashboardActions"
    },
    {
      "label": "Einzahlen",
      "icon": "MonetizationOnIcon",
      "description": "Die gesammelten Punkte kann ich hier in unsere Projekte investieren",
      "to": "/DashboardProjects"
    },  {
      "label": "Unterstützen",
      "icon": "ThumbUpIcon",
      "description": "Hier finde ich alle aktiven und möglichen Aktionen",
      "to": "/DashboardSupport"
    },    {
      "label": "Ideen einreichen",
      "icon": "EmojiObjectsIcon",
      "description": "Hier finde ich alle aktiven und möglichen Aktionen",
      "to": "/DashboardIdears"
    },
    {
      "label": "Marktplatz",
      "icon": "ShopIcon",
      "description": "Hier finde ich alle aktiven und möglichen Aktionen",
      "to": "/DashboardMarketplace"
    },
    {
      "label": "Newsfeed",
      "icon": "NotificationsIcon",
      "description": "Alle Informationen zum meinem Profil sind hier zu finden",
      "to": "/DashboardNewsfeed"
    }
  ]`,
      (key, value) => {
        if (key === 'icon') {
          return iconsMap[value];
        } else {
          return value;
        }
      }
    )
  }
];
