export var primary = "#414361"
export var primaryLight = "#495368"
export var primaryDark = "#09142a"
export var secondary = "#296356"
export var inheritDefault1 = "#fefefe"
export var inheritDefault2 = "#f8f9ff"
export var second = "#2A2D43"
export var indigo = "#62466B"
export var purple = "#793de6"
export var pink = "#EF626C"
export var red = "#B84A62"
export var orange = "#DB5A42"
export var yellow = "#FFD275"
export var green = "#599f82"
export var teal = "#B5C2B7"
export var cyan = "#A5C4D4"
export default {"primary":"#414361","primaryLight":"#495368","primaryDark":"#09142a","secondary":"#296356","inheritDefault1":"#fefefe","inheritDefault2":"#f8f9ff","second":"#2A2D43","indigo":"#62466B","purple":"#793de6","pink":"#EF626C","red":"#B84A62","orange":"#DB5A42","yellow":"#FFD275","green":"#599f82","teal":"#B5C2B7","cyan":"#A5C4D4"}
