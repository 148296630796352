import { initStore } from './store';

import avatar1 from '../assets/images/avatars/avatar1.jpg';
import avatar2 from '../assets/images/avatars/avatar2.jpg';
import avatar3 from '../assets/images/avatars/avatar3.jpg';

import victoria from '../assets/images/avatars/victoria.jpg';
import arne from '../assets/images/avatars/arne.jpg';
import janik from '../assets/images/avatars/janik.jpg';

import ebike from '../assets/images/stock-photos/ebike.jpg';
import photovoltaic from '../assets/images/stock-photos/photovoltaic.jpg';
import boy from '../assets/images/stock-photos/boy-child.jpg';
import fairtrade from '../assets/images/stock-photos/fairtrade.jpg';
import wall from '../assets/images/stock-photos/green-wall.jpg';
import water from '../assets/images/stock-photos/water.jpg';

import supportBigImage from '../assets/images/stock-photos/success.jpg';
import supportBigImage2 from '../assets/images/stock-photos/success2_500x620.jpg';
import supportBigImage3 from '../assets/images/stock-photos/success4_500x620.jpg';
import supportBigImage4 from '../assets/images/stock-photos/success6_500x620.jpg';
import supportSmalImage from '../assets/images/illustrations/tree_swing.svg';

const configureStore = () => {
  const actions = {
    FUND_PROJECT: (curState, payload) => {
      console.log('FUND_PROJECT ' + JSON.stringify(payload));
      const points = Number(payload.points);
      const projectIndex = curState.projects.findIndex(
        p => p.id === payload.id
      );
      const updatedProjects = [...curState.projects];
      updatedProjects[projectIndex] = {
        ...curState.projects[projectIndex],
        pointsCollected:
          curState.projects[projectIndex].pointsCollected + points
      };

      const updatedProfile = {
        ...curState.profile,
        pointsAvailable: curState.profile.pointsAvailable - points,
        pointsCollected: curState.profile.pointsCollected + points,
        pointsSpent: curState.profile.pointsSpent + points
      };

      return {
        ...curState,
        projects: updatedProjects,
        profile: updatedProfile
      };
    }
  };

  initStore(actions, {
    projects: [
      {
        id: 1,
        title: 'E-Poolbikes',
        actionState: 'available',
        teaserText:
          'Wir schaffen 10 E-Bikes an, die von allen Mitarbeitern für kurze Strecken genutzt werden können.',
        pointsCollected: 3,
        pointsNeedet: 42,
        co2Savings: 4,
        image: ebike,
        owner: {
          name: 'Arne Kiewit',
          avatar: arne,
          description:
            'Rund 15 % unserer Dienstfahrten sind Kurzstrecken mit weniger als 5 km Entfernung. Ab auf‘s Rad, oder?'
        },

        activeUser: [
          { id: '1', alt: 'alias', imageSrc: avatar1, co2Savings: 3 },
          { id: '2', alt: 'alias', imageSrc: avatar2, co2Savings: 2 },
          { id: '3', alt: 'alias', imageSrc: avatar3, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar3, co2Savings: 4 }
        ],
        dialog: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Du bist ein guter Mensch!',
          subheading: 'Vielen Dank, dass du unterstützen möchtest!',
          text:
            'Du kannst nun auswählen, wie viele Punkte du für dieses Projekt spenden möchtest. Die Punkte verdienst du dir über die Aktionen, die du erfolgreich umsetzt.',
          inputLabel: 'Anzahl Punkte',
          buttonLabel: 'Jetzt unterstützen'
        }
      },
      {
        id: 2,
        title: 'Photovoltaik',
        actionState: 'available',
        teaserText:
          'Anstelle von umweltschädlichem Kohle- oder Atomstrom werden wir Strom durch Solarkraft erzeugen.',
        pointsCollected: 40,
        pointsNeedet: 60,
        co2Savings: 6,
        image: photovoltaic,
        owner: {
          name: 'Victoria Jagalski',
          avatar: victoria,
          description:
            'Durch Solarenergie leisten wir einen wirksamen Beitrag zum Klimaschutz und sparen im Jahr rund 6.500 Euro.'
        },
        activeUser: [
          { id: '1', alt: 'alias', imageSrc: avatar1, co2Savings: 3 },
          { id: '2', alt: 'alias', imageSrc: avatar2, co2Savings: 2 },
          { id: '3', alt: 'alias', imageSrc: avatar3, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar3, co2Savings: 4 }
        ],
        dialog: {
          bigImage: supportBigImage2,
          smalImage: supportSmalImage,
          heading: 'Du bist ein guter Mensch!',
          subheading: 'Vielen Dank, dass du unterstützen möchtest!',
          text:
            'Du kannst nun auswählen, wie viele Punkte du für dieses Projekt spenden möchtest. Die Punkte verdienst du dir über die Aktionen, die du erfolgreich umsetzt.',
          inputLabel: 'Anzahl Punkte',
          buttonLabel: 'Jetzt unterstützen'
        }
      },
      {
        id: 3,
        title: 'Mitmachkinder',
        actionState: 'available',
        teaserText:
          'Für jeden eingezahlten Punkt spenden wir 10 € an den gemeinnützigen Verein Mitmachkinder e.V.',
        pointsCollected: 3,
        pointsNeedet: 42,
        co2Savings: 3,
        image: boy,
        owner: {
          name: 'Arne Kiewit',
          avatar: arne,
          description:
            'Das Förderprogramm ermöglicht Kindern die Teilnahme an Freizeitaktivitäten und Lernprogrammen.'
        },
        activeUser: [
          { id: '1', alt: 'alias', imageSrc: avatar1, co2Savings: 3 },
          { id: '2', alt: 'alias', imageSrc: avatar2, co2Savings: 2 },
          { id: '3', alt: 'alias', imageSrc: avatar3, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar3, co2Savings: 4 }
        ],
        dialog: {
          bigImage: supportBigImage3,
          smalImage: supportSmalImage,
          heading: 'Du bist ein guter Mensch!',
          subheading: 'Vielen Dank, dass du unterstützen möchtest!',
          text:
            'Du kannst nun auswählen, wie viele Punkte du für dieses Projekt spenden möchtest. Die Punkte verdienst du dir über die Aktionen, die du erfolgreich umsetzt.',
          inputLabel: 'Anzahl Punkte',
          buttonLabel: 'Jetzt unterstützen'
        }
      },
      {
        id: 4,
        title: 'Regenwasser',
        actionState: 'available',
        teaserText:
          'Wir installieren einen 10.000 Liter großen Speicher zur Nutzung von Grauwasser.',
        pointsCollected: 23,
        pointsNeedet: 60,
        co2Savings: 7,
        image: water,
        owner: {
          name: 'Janik Seitzer',
          avatar: janik,
          description:
            'Durch die Nutzung von Regenwasser können wir unseren Trinkwasserverbrauch jährlich um 60.000 Liter reduzieren.'
        },
        activeUser: [
          { id: '1', alt: 'alias', imageSrc: avatar1, co2Savings: 3 },
          { id: '2', alt: 'alias', imageSrc: avatar2, co2Savings: 2 },
          { id: '3', alt: 'alias', imageSrc: avatar3, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar3, co2Savings: 4 }
        ],
        dialog: {
          bigImage: supportBigImage4,
          smalImage: supportSmalImage,
          heading: 'Du bist ein guter Mensch!',
          subheading: 'Vielen Dank, dass du unterstützen möchtest!',
          text:
            'Du kannst nun auswählen, wie viele Punkte du für dieses Projekt spenden möchtest. Die Punkte verdienst du dir über die Aktionen, die du erfolgreich umsetzt.',
          inputLabel: 'Anzahl Punkte',
          buttonLabel: 'Jetzt unterstützen'
        }
      },
      {
        id: 5,
        title: 'Green Office Wall',
        actionState: 'available',
        teaserText:
          'Durch eine Green Office Wall schaffen wir bessere Luft und ein angenehmeres Klima in unserem Betriebsrestaurant.',
        pointsCollected: 7,
        pointsNeedet: 10,
        co2Savings: 2,
        image: wall,
        owner: {
          name: 'Arne Kiewit',
          avatar: arne,
          description:
            'Zugegeben, eher was für die Optik, aber wir können uns ja auch mal was gönnen, oder?'
        },
        activeUser: [
          { id: '1', alt: 'alias', imageSrc: avatar1, co2Savings: 3 },
          { id: '2', alt: 'alias', imageSrc: avatar2, co2Savings: 2 },
          { id: '3', alt: 'alias', imageSrc: avatar3, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar3, co2Savings: 4 }
        ],
        dialog: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Du bist ein guter Mensch!',
          subheading: 'Vielen Dank, dass du unterstützen möchtest!',
          text:
            'Du kannst nun auswählen, wie viele Punkte du für dieses Projekt spenden möchtest. Die Punkte verdienst du dir über die Aktionen, die du erfolgreich umsetzt.',
          inputLabel: 'Anzahl Punkte',
          buttonLabel: 'Jetzt unterstützen'
        }
      },
      {
        id: 6,
        title: 'Fairtrade',
        actionState: 'available',
        teaserText:
          'Wir stellen unsere Werbemittel und Weihnachtspräsente um auf 100 % Fairtrade.',
        pointsCollected: 9,
        pointsNeedet: 20,
        co2Savings: 4,
        image: fairtrade,
        owner: {
          name: 'Victoria Jagalski',
          avatar: victoria,
          description:
            'Geschenke machen wir mit Freude… umso mehr noch, wenn sie auch mit dergleichen hergestellt wurden.'
        },
        activeUser: [
          { id: '1', alt: 'alias', imageSrc: avatar1, co2Savings: 3 },
          { id: '2', alt: 'alias', imageSrc: avatar2, co2Savings: 2 },
          { id: '3', alt: 'alias', imageSrc: avatar3, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar3, co2Savings: 4 }
        ],
        dialog: {
          bigImage: supportBigImage2,
          smalImage: supportSmalImage,
          heading: 'Du bist ein guter Mensch!',
          subheading: 'Vielen Dank, dass du unterstützen möchtest!',
          text:
            'Du kannst nun auswählen, wie viele Punkte du für dieses Projekt spenden möchtest. Die Punkte verdienst du dir über die Aktionen, die du erfolgreich umsetzt.',
          inputLabel: 'Anzahl Punkte',
          buttonLabel: 'Jetzt unterstützen'
        }
      }
    ]
  });
};

export default configureStore;
